@import "../../../styles/sass/NewThemeVariables.scss";

.geo-targeting-container {
  // overflow: hidden;
  height: 94%;
  font-size: 13px;
  .geo-targeting-files-container {
    color: #eb367f;
    .geo-targeting-files-search-container {
      display: flex;
      margin: 15px 0;
      height: 30px;
      justify-content: space-between;
      p {
        margin: 0 10px;
      }
      .aide-resources-search {
        padding: 0;
        width: 40%;
        .input-search {
          height: inherit;
        }
      }
    }
    .no-files-available,
    .no-data-available {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 70px;
    }
  }
}

.geo-input-field-row-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  .input-field-sub-container {
    width: 48%;
    min-width: 250px;
    max-width: 500px;
    .select-input-search {
      width: 100%;
    }
  }
}
