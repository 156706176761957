@import "../../../styles/sass/NewThemeVariables.scss";

.apps-row {
  margin: 5px 0px 0px;
  .no-apps {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    color: $primaryColor;
    font-size: $primaryFontSizeS;
    img {
      height: 20vh;
      width: 100%;
    }
  }
  &.all-apps {
    margin: 0px;
    display: flex;
    flex-direction: row;
  }

  .apps-row-title {
    margin: 0px 0px 8px 0px;
    text-transform: capitalize;
    font-size: 18px;
  }

  .apps-row-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($appSide, 0.2fr));
    // grid-gap: $appRowGridGap;
    grid-row-gap: $appRowGridGapConfig;
    grid-column-gap: $appColumnGridGap;

    &.all-apps {
      display: flex;
      flex-direction: row;
      grid-gap: 0px;
      .card-container {
        margin: 20px;
      }
    }

    .card-container {
      position: relative;
      display: flex;
      cursor: pointer;
      transition: all $transition;
      // box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
      // border-radius: 10px;

      &:hover {
        transform: scale(1.05);
        .popover-container .more-vert-icon-style {
          color: #fff;
          &:hover {
            color: $primaryColor;
          }
        }
        .app &:hover {
          // transform: scale(1.05);
          background-color: $primaryColor !important;
          color: $appBGColor !important;
          box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
          border-radius: 10px;
        }
      }

      .popover-container {
        position: absolute;
        top: 13px;
        z-index: 9;
        right: 10px;
        &:hover {
          .more-vert-icon-style {
            color: $primaryColor;
          }
        }
      }

      .app {
        width: $appSide;
        height: $appSide;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5%;
        border: 1px solid $inputEleBlackBorderColor;

        .more-vert-app {
          color: yellow;
        }

        .app-icon {
          font-size: $appIconSizeLarge;
          padding: 15px;
        }

        .app-title {
          margin: 0px;
          text-align: center;
          font-size: small;
          width: 120px;
          height: 2.8em;
          line-height: 1.4em;
          display: flex;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }

        &:hover {
          // transform: scale(1.05);
          background-color: $primaryColor !important;
          color: $appBGColor !important;
          box-shadow: 0px 0px 20px rgba(193, 193, 193, 0.25);
          border-radius: 10px;
        }

        .card-item {
          color: $primaryColor;
        }
      }
    }
  }
}

.apps-row.light {
  .apps-row-title {
    color: $primaryHeaderColor;
  }

  .apps-row-grid {
    .app {
      // background-color: $appBGColor;
      color: $primaryColor;
    }
  }
}
