@import "../../../../../styles/sass/NewThemeVariables.scss";

.segment-threshhold-container {
  display: flex;
  align-items: center;
  // padding: 10px 0px;
  width: 70rem;

  & .segment-threshhold-histogram {
    //width: 850px;
    height: auto;
    margin: 2rem 0px 0px 2rem;
  }
}

.dialog-popup {
  margin-left: 15px;
  margin-right: 10px;
}
.segment-historic-zoom-container {
  position: relative;
  top: 1rem;
  left: 1rem;
  height: 25px;
  width: fit-content;
  display: flex;
  .segment-zoom-in,
  .segment-zoom-out {
    color: #46596a;
    border-radius: 5px;
    margin-right: 5px;
    padding: 2px;
  }
  .segment-zoom-in:hover,
  .segment-zoom-out:hover {
    color: #eb367f;
  }
}
.segment-historic-image {
  margin-left: 15px;
  // margin-right: 10px;
  width: 880px;
  height: 560px;
  overflow-y: scroll;
  overflow-x: scroll;
  /* width */
  img {
    margin-top: 10px;
  }
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b1b1b1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
