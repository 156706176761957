@import "../../../../styles/sass/NewThemeVariables.scss";

.view-runs-sidenav-container {
  .view-runs-filters-container {
    position: absolute;
    right: 40px;
    top: 115px;
    .view-runs-filters {
      // padding: 16px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      margin-bottom: 5px;
      float: right;

      .view-runs-filters-search-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $inputEleBorderColor;
        border-radius: 3px;

        .input-label {
          width: 70%;
          border-radius: 3px;
          border: 0px;
          height: $inputEleHeight;
          box-sizing: border-box;
          color: $primaryColor;
          font-size: $primaryFontSizeXS;
          padding-left: 6px;

          &::placeholder {
            color: $primaryColor;
            opacity: 0.5;
          }
        }
        .cancel-icon {
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $primaryColor;
          font-size: 15px;
        }
        .search-icon {
          width: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $fifthColor;
          font-size: 18px;
          height: 26px;
          border-left: 1px solid $inputEleBorderColor;
        }
      }

      .view-runs-filters-date-container {
        .view-runs-filters-date {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .date-title {
            font-size: 10px;
            color: $primaryColor;
          }

          .date-icon {
            font-size: 16px;
            color: $primaryColor;
          }
        }
      }
    }
  }

  .no-runs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 8%;
    color: $primaryColor;
    font-size: $primaryFontSizeS;
    img {
      height: 20vh;
      width: 100%;
    }
  }

  .view-runs-container {
    .view-runs-category {
      color: $primaryColor;
      font-size: 13px;
      border-bottom: 1px solid #d1d3d3;

      // &:first-child {
      //   border-top: 1px solid #d1d3d3;
      // }

      .run-item {
        color: $primaryColor;
        padding: $sidenavItemPadding;
        //margin-bottom: $sidenavItemMarginBottom;
        display: flex;
        flex-direction: column;
        align-items: left;

        .run-title {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 5px;

          .run-text-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            .run-icon {
              margin-right: 7px;
              width: 20px;
              height: 20px;

              &.Running {
                background-image: url(../../../../assets/images/run_normal.svg);
                background-repeat: no-repeat;
              }

              &.Queued {
                background-image: url(../../../../assets/images/run_normal.svg);
                background-repeat: no-repeat;
              }

              &.Success {
                background-image: url(../../../../assets/images/run_sucess.svg);
                background-repeat: no-repeat;
              }

              &.Failed {
                background-image: url(../../../../assets/images/run_fail.svg);
                background-repeat: no-repeat;
              }
            }

            .run-text {
              margin: 0px;
              font-size: $primaryFontSizeXS;
              align-items: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 120px;
              overflow: hidden;
            }
          }

          .run-menu-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .delete-icon {
              color: $primaryColor;
              font-size: 20px;
            }
          }
        }

        .run-sub-title {
          font-size: $primaryFontSizeXS;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .run-text {
            margin: 0px;
            display: flex;
            font-size: $primaryFontSizeXS;
            justify-content: left;
            align-items: center;
            opacity: 0.5;
            &.Running {
              color: $fourthColor;
              opacity: 1;
            }
          }
        }

        &.active,
        &:hover {
          cursor: pointer;
          background-color: $sidenavActiveBGColor;
          color: $primaryColor;
        }

        // &.previous_runs {
        //   &.active,
        //   &:hover {
        //     cursor: pointer;
        //     background-color: $sidenavActiveBGColor;
        //     color: $sidenavActiveFontColor;
        //   }
        // }

        // &.queued,
        // &.running {
        //   pointer-events: none;
        // }
      }

      .run-show-more {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        margin-bottom: 8px;
      }

      hr.run-item-separator {
        margin: 0px 14px 8px 14px;
        border: none;
        border-bottom: 1px solid #d1d3d3;
      }

      hr.run-item-separator:last-child {
        border: none;
      }
    }
  }

  .models-menu {
    display: flex;

    .models-item {
      padding-right: 30px;
      text-align: center;
      cursor: pointer;

      &.schedule-disabled {
        opacity: 0.5;
        pointer-events: none;
      }

      .models-item-div {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
        color: $primaryColor;

        p {
          font-size: 0.875rem;
        }

        .selected-model-icon {
          margin-left: 5px;
          color: $secondaryColor;
          font-size: $primaryFontSizeXXS;
        }
        &.active {
          background-color: white;
          color: $secondaryColor;
          border-bottom: 3px solid $secondaryColor;
          font-weight: bolder;
        }
      }
    }
  }
}
