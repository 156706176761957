@import "../../../../../styles/sass/NewThemeVariables.scss";

.models-container {
  background-color: white;
  width: 100%;
  border-radius: 4px;
  height: 100%;

  .models-selection-title-container {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: $appBGColorLight;

    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }

    .models-selection-div {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      margin: 2px;

      &.disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &.active-selection-div {
        color: #ffffff;
        background: $primaryColor;
        border-radius: 5px;
        font-weight: bolder;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
      }

      .dataset-icon {
        font-size: 17px;
        &.active-selection {
          color: #ffffff;
        }
      }

      .zoom-icon {
        margin-left: auto;
        font-size: 17px;
      }

      .models-selection-title {
        font-weight: 600;
        cursor: pointer;
        font-size: $primaryFontSizeXS;
        padding-left: 6px;

        &.active-selection {
          font-weight: bolder;
        }
      }
    }
    .zoom-icon-div {
      margin-left: auto;
    }
  }

  .model-items-container {
    display: flex;
    background-color: $appBGColorDark;
    // border: 1px solid $sidenavBorderColor;
    padding: 5px;
    border-radius: 4px 4px 0px 0px;
    position: relative;

    .run-type-local-parameters-row {
      display: flex;
      align-items: center;
      padding: 3px 8px;

      &.disabled {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    .model-user-input {
      padding: 5px 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.approximate-time-container {
        position: absolute;
        right: 5px;
        bottom: 10px;
      }

      .model-text {
        color: $primaryColor;
        font-size: $primaryFontSizeXS;
      }

      .model-switch-container {
        margin-right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px 15px 0px 15px;

        .model-text {
          color: $primaryColor;
          font-size: $primaryFontSizeXS;
        }

        .selected-model-icon {
          color: $primaryColor;
          opacity: 0.5;
          margin: 0px 5px;
          font-size: 27px;

          &.selected {
            color: $secondaryColor;
            opacity: 1;
          }
        }
      }
      .disabled {
        opacity: 1 !important;
        pointer-events: none;
      }
    }
  }

  .models-menu {
    display: flex;
    // justify-content: space-between;

    .models-item {
      padding: 8px 35px 0px;
      text-align: center;
      cursor: pointer;

      .models-item-div {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 8px;
        p {
          text-transform: uppercase;
        }

        .selected-model-icon {
          margin-left: 5px;
          color: $secondaryColor;
          font-size: $primaryFontSizeXXS;
        }
        &.active {
          background-color: white;
          color: $secondaryColor;
          border-bottom: 3px solid $secondaryColor;
          font-weight: bolder;
        }
      }
    }
  }

  .models-selections-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    background-color: $appBGColorLight;
    min-height: calc(100% - 87px) !important;
    max-height: calc(100% - 469px) !important;
    overflow-y: auto;
    height: 100%;
    &.disabled {
      opacity: 0.2;
      pointer-events: none;
    }
    .nlp-model-params-container {
      margin: 20px 22px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-gap: 10px;
      align-items: end;
      .datasets-selections-local-parameters-row {
        .number-of-topics-checkbox {
          position: relative;
          top: 1.8rem;
          left: 8rem;
          height: 36px;
          width: 100px;
          display: flex;
          align-items: center;
        }
      }
    }
    .models-selections-column {
      border-collapse: collapse;

      &.left {
        //border-right: none;
        width: 100%;
        //border-radius: 4px;
      }

      &.right {
        width: 100%;
        height: 100%;
        //margin-top: 10px;
        //border-radius: 4px 4px 0px 0px;
      }
      .no-data-holder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 130px;
          width: 130px;
        }

        .no-datasets-icon {
          font-size: 30px;
          cursor: pointer;
          color: $secondaryColor;
        }
      }
      .models-selection-content {
        height: 100%;
        // border: 1px solid $sidenavBorderColor;
        // border-radius: 0px 0px 4px 4px;
        // border-top: 0px;

        &.no-datasets-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 150px;
          min-height: $noDataAvailableMinHeight !important;
          max-height: $noDataAvailableMaxHeight !important;

          .no-datasets-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              height: 130px;
              width: 130px;
            }

            .no-datasets-icon {
              font-size: 30px;
              cursor: pointer;
              color: $secondaryColor;
            }
          }
        }

        &.right {
          overflow-x: auto;
          //padding-bottom: 10px;
          // display: grid;
          // grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
          // grid-template-rows: repeat(2, 50px);
          &::-webkit-scrollbar {
            display: none;
          }
        }

        &.no-modelconfig-container {
          display: flex;
          justify-content: center;
          align-items: center;
          .no-info-holder {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
              height: 130px;
              width: 130px;
            }

            .no-info-icon {
              font-size: 30px;
              cursor: pointer;
              color: $secondaryColor;
            }
          }
        }
        .datasets-selections-local-parameters-row {
          padding: 10px 8px 0px 10px;
          .no-data {
            color: $primaryColor;
            text-align: center;
            font-size: 14px;
          }
          .dataset-channel,
          .dataset-campaign {
            padding-left: 15px;
          }
        }
        .category-error-message {
          color: red;
          font-size: 12px;
          margin: 0px 5px;
        }

        .datasets-selections-container {
          display: flex;
          width: 100%;

          .datasets-selections-column {
            .datasets-selections-column-type {
              margin-top: 5px;
              .datasets-selections-column-type-title {
                padding-left: 5px;
                font-weight: 600;
                text-transform: capitalize;
              }
            }

            &.left {
              width: 20%;
              min-height: $modelSelectionMinHeight !important;
              max-height: $modelSelectionMaxHeight !important;
              overflow-y: auto;
            }

            &.right {
              display: flex;
              flex-direction: column;
              border-left: 1px solid $sidenavBorderColor;
              border-collapse: collapse;
              width: 80%;
              min-height: $modelSelectionMinHeight !important;
              max-height: $modelSelectionMaxHeight !important;
              overflow-y: auto;
              .active-dataset-information {
                min-height: 20px;
                padding: 10px;
                background: $activeDatasetColor;
                font-size: 14px;
                color: $primaryColor;

                .end-box {
                  display: flex;
                  font-size: $primaryFontSizeS;
                  .name {
                    font-weight: 800;
                    width: 50%;
                    display: flex;
                  }
                  .divider {
                    border: 0.5px solid #46596a;
                    height: 20px;
                    margin: 0 10px;
                  }
                  .report-link-container {
                    display: flex;
                    align-items: center;
                    .report-link {
                      color: inherit;
                      text-decoration: none;
                    }
                    .report-link:hover {
                      color: #eb367f;
                    }
                    .material-icons-outlined {
                      font-size: inherit;
                    }
                  }
                  .report-link-container:hover {
                    color: #eb367f;
                  }

                  .ratification-score {
                    padding-left: 10px;
                    width: 10%;
                    font-size: $primaryFontSizeXS;
                  }
                  .start-date {
                    // padding-left: 20px;
                    // width: 20%;
                    // border-right: 1px solid $primaryColor;
                    font-size: $primaryFontSizeXS;
                  }
                  .end-date {
                    padding-left: 10px;
                    // width: 20%;
                    font-size: $primaryFontSizeXS;
                  }
                }
              }

              .dataset-selections {
                display: flex;
                height: calc(100% - 42px);
              }
            }

            .dataset-checkbox-container {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 1px 5px 1px 20px;
              cursor: pointer;

              &.active {
                background-color: $activeDatasetColor;
              }

              .info-icon {
                margin-left: 5px;
                width: 10%;
                color: $fifthColor;
                font-size: $primaryFontSizeXS;
                // &:hover {
                //   color: $secondaryColor;
                // }
              }

              .separator {
                color: #46596a;
                background-color: #46596a;
                height: 0.1;
                opacity: 0.5;
              }

              .dataset-checkbox-icon {
                width: 13%;
                color: $inputEleBorderColor;
                font-size: 15px;

                &.active {
                  color: #4eafb3;
                }
              }

              label {
                width: auto;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .dataset-source-tag {
                margin-left: 4px;
                background-color: #f5c405;
                color: #41596c;
                font-size: 8px;
                padding: 1px 4px;
                border-radius: 10px;
                font-weight: 700;
              }
            }
            .week-info {
              padding: 1px 5px 1px 20px;
              color: $primaryColor;
              font-size: 10px;
            }

            .dataset-category-container {
              border-bottom: 1px solid $sidenavBorderColor;
              padding: 10px;
            }

            .datasets-selections-kpi-row {
              border-right: 1px solid $sidenavBorderColor;
              width: 50%;
              .select-kpi-text {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                p {
                  padding: 8px;
                  font-weight: 600;
                  text-transform: capitalize;
                  color: #eb367f;
                }
                .kpi-action-texts {
                  display: flex;
                  justify-content: end;
                  p {
                    color: $primaryColor;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
                .checkbox-present {
                  width: 100%;
                  p {
                    padding: 0px 8px !important;
                  }
                }
                .checkbox-absent {
                  width: 70%;
                }
              }
              .top-n {
                .competitor-checkbox-container {
                  display: flex;
                  justify-content: end;
                  margin: 0 10px;
                  .competitor-checkbox {
                    display: flex;
                    align-items: center;
                    .checkbox-category {
                      display: flex;
                      align-items: center;
                      .top-n-competitor {
                        margin: 0px 2px;
                        input {
                          border-radius: 3px;
                          border: 1px solid $inputEleBorderColor;
                          width: 35px !important;
                          color: $primaryColor;
                          box-sizing: border-box;
                          padding-left: $inputLeftSpacing;
                          font-family: "Hind Siliguri";
                          font-size: $primaryFontSizeXS;
                        }
                        .error-top-n {
                          border: 1px solid red;
                        }
                      }
                    }
                  }
                }
                .error-message {
                  text-align: right;
                  font-size: 11px;
                  margin: 0px 10px;
                  color: red;
                  font-weight: 500;
                  padding: 0px;
                  text-transform: none;
                }
              }
              .kpi-container {
                position: relative;
                height: calc(100% - 45px) !important;
                //padding: 10px 10px 10px 20px;
                .error-message {
                  text-align: right;
                  font-size: 11px;
                  margin: 0px 10px;
                  color: red;
                }
                .nielsen-comp-checkbox {
                  position: absolute;
                  top: -32px;
                  right: 10px;
                  display: flex;
                  align-items: center;
                  .checkbox-category {
                    display: flex;
                    align-items: center;
                    .top-n-competitor {
                      margin: 0px 2px;
                      input {
                        border-radius: 3px;
                        border: 1px solid $inputEleBorderColor;
                        width: 35px !important;
                        color: $primaryColor;
                        box-sizing: border-box;
                        padding-left: $inputLeftSpacing;
                        font-family: "Hind Siliguri";
                        font-size: $primaryFontSizeXS;
                      }
                      .error-top-n {
                        border: 1px solid red;
                      }
                    }
                  }
                }
                .kpi-selections-container {
                  // column-count: 2;
                  column-fill: auto;
                  padding: 8px;
                  height: calc(100vh - 500px) !important;
                  min-height: 120px;
                  overflow-y: auto;
                  .label {
                    font-size: 14px;
                  }
                  .input-variables {
                    margin-top: 10px;
                  }
                  .kpi-checkbox-container {
                    display: flex;
                    align-items: center;
                    .kpi-checkbox-icon {
                      width: 10%;
                      color: $inputEleBorderColor;
                      font-size: 15px;

                      &.active {
                        color: #4eafb3;
                      }
                    }
                    &.mandatory-kpi {
                      color: $fifthColor;
                      pointer-events: none;
                    }
                    label {
                      width: 90%;
                      cursor: pointer;
                    }
                  }
                  .kpi-parameters-container {
                    margin: -5px 1px 8px 1px;
                    .kpi-parameters {
                      display: grid;
                      grid-template-columns: repeat(3, 1fr);
                      grid-gap: 10px;
                      margin: 0 10%;
                    }
                  }
                }
              }
            }

            .datasets-selections-local-parameters-item {
              width: 50%;
              overflow-y: auto;
              overflow-x: hidden;

              .dataset-parameter-text {
                padding: 8px;
                font-weight: 600;
                // border-bottom: 1px solid $sidenavBorderColor;
                color: #eb367f;
                font-size: 14px;
              }
              .dataset-parameter-container {
                padding: 8px;
              }
            }

            .datasets-selections-local-parameters-row {
              padding: 5px 8px 0px 4px;
            }
            .no-category {
              color: #46596a80;
              opacity: 0.5;
            }
          }
        }
      }
      .community-target {
        display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        grid-template-rows: repeat(3, 70px);
      }
      .input-sub-container {
        padding: 10px 8px 0px 10px;
        .input-field-sub-container {
          display: flex;
          // align-items: center;
          flex-direction: column;
          &.no-label {
            display: flex;
            align-items: flex-end;
            .input-text-box {
              width: 100%;
            }
          }
          .scoring-week-selection {
            display: flex;
            justify-content: space-between;
          }
          label {
            color: $primaryColor;
            margin-right: 5px;
            font-weight: 600;
            margin-bottom: 0px;
            width: 70px;
            font-family: "Hind Siliguri" !important;
          }
          .input-label {
            width: 99%;
            height: 26px;
            border: 1px solid $inputEleBorderColor;
            color: $primaryColor;
            border-radius: $primaryBR;
          }
          input {
            border-radius: 3px;
            border: 1px solid $inputEleBorderColor;
            height: 30px;
            width: 160px;
            color: $primaryColor;
            box-sizing: border-box;
            padding-left: $inputLeftSpacing;
            text-transform: uppercase;
            font-family: "Hind Siliguri";
            font-size: $primaryFontSizeXS;
            margin-left: -1px;
          }
        }
      }
    }
    .padding-right-12 {
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .run-menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin: 10px 0px;
    margin-left: auto;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;

    .run-menu-item-icon {
      color: $primaryColor;
      margin-right: 5px;
      font-size: 17px !important;
      line-height: 0 !important;
    }

    &.download {
      //margin-left: 87px;
      margin-right: 0;
    }

    &.no-input-button {
      margin-left: auto;
    }

    &.input-download {
      margin-left: auto;
      margin-right: 0px;
    }

    .run-menu-item {
      border-radius: 4px;
      border: 1px solid rgba(37, 177, 230, 0.15);
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 10px;
      margin-left: 8px;
      cursor: pointer;

      &.disabled-pointer {
        pointer-events: none;
        opacity: 0.5 !important;
      }

      &.shaded {
        background-color: rgba(37, 177, 230, 0.15);
      }

      .run-menu-item-icon {
        color: $primaryColor;
        margin-right: 5px;
      }

      .run-menu-item-text {
        margin: 0px;
        color: $primaryColor;
      }
    }
  }
}

.no-info-center {
  display: flex;
  justify-content: center;
  // align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  color: $inputEleBorderColor;
}

.no-info-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    height: 130px;
    width: 130px;
  }

  .no-info-icon {
    font-size: 30px;
    cursor: pointer;
    color: $secondaryColor;
  }
}

.full-line {
  grid-column: 1 / -1;
}
