@import "../../../styles/sass/NewThemeVariables.scss";

header {
  display: flex;
  height: $headerHeight;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0px 10px;
  position: fixed;
  width: 100%;
  z-index: 1;
  box-shadow: 0px 4px 10px rgba(221, 221, 221, 0.25);

  .top-left-nav {
    display: flex;
    align-items: center;
    // padding-left: 2%;
    .rb-logo-container {
      display: flex;
      align-items: center;
      padding: 0px 10px;
      .rb-logo {
        height: $rbLogoHeight;
        width: auto;
      }
    }
    .rb-logo-text {
      color: $sidenavActiveFontColor;
      // border-left: 1px solid $headerIconColor;
      margin: 0px;
      padding: 20px 0px 10px 20px;
      font-weight: 700;
      font-size: 18px;
      text-decoration: none;
    }
  }

  .header-menu {
    display: flex;
    align-items: center;

    .user-name {
      color: white;
      margin-right: 7px;
      font-size: 15px;
    }
  }

  .notification-container,
  .all-apps-container,
  .user-info-container {
    display: flex;
    align-items: center;
    height: 100%;
    padding: $primaryFontSizeS;

    // &:hover {
    //   // background-color: #546c7f;
    // }

    .header-menu-icon {
      font-size: $headerIconSize;
      width: $headerIconSize;
      //margin-left: $headerIconMarginRight;

      &.notification {
        position: relative;
        //   &.badge:after{
        //     content:"100";
        //     position: absolute;
        //     background: $secondaryColor;
        //     height:8px;
        //     top:1px;
        //     right:1px;
        //     width:8px;
        //     text-align: center;
        //     //line-height: 1rem;;
        //     font-size: 1rem;
        //     border-radius: 50%;
        //     color:white;
        //     border:1px solid $secondaryColor;
        // }
      }
    }
  }
}

header.light {
  background-color: $headerBGLight;
  .header-menu-icon {
    color: $headerIconColor;
  }
}

.banner {
  position: fixed;
  top: $headerHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $fourthColor;
  color: $primaryColor;
  width: 100%;
  padding: 0px 10px;
  p {
    font-size: 11px;
    margin: 0px;
    margin-right: 4px;
  }
  a {
    color: $primaryColor;
    font-size: 11px;
  }
}
