@import "../../../styles/sass/NewThemeVariables.scss";

.message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: $noDataAvailableMinHeight;
  background: $appBGColorLight;

  img {
    height: 140px;
    width: 100%;
  }

  .message-icon {
    font-size: 35px;
    &.Running {
      color: #f5c405;
    }

    &.Queued {
      color: $primaryColor;
    }

    &.Success {
      color: #179aa7;
    }

    &.Failed {
      color: #fd6320;
    }
  }

  .message-text {
    font-size: 14px;
    color: $primaryColor;
    width: 60%;
    display: flex;
    justify-content: center;
  }
  .success-info-message {
    margin-top: 15px;
    font-size: $primaryFontSizeXS;
    color: $primaryColor;
    width: 50%;
    text-align: center;
  }
}
