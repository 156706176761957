@import "../../../styles/sass/NewThemeVariables.scss";

[data-letters]:before {
  content: attr(data-letters);
  display: inline-block;
  font-size: 1em;
  width: 2em;
  height: 2em;
  line-height: 2em;
  text-align: center;
  border-radius: 50%;
  background: $primaryColor;
  vertical-align: middle;
  margin-right: 0.6em;
  color: white;
}

.more-vert-icon-style {
  color: $primaryColor;
}

.menu-item {
  color: $sidenavActiveFontColor;
}
.popup-btn {
  display: flex;
  justify-content: flex-end;
  margin: 7px 15px;
  border-top: none !important;
  padding: 10px 0px;
}
.popup-title {
  font-size: 15px;
  font-family: "Hind Siliguri";
  width: 225px;
  margin: 20px;
  color: #46596a;
}

.share-dialog-container {
  width: 650px;
  p {
    color: $primaryColor;
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 32px;
    padding: 5px 15px;
  }

  .dialog-config-name {
    color: $secondaryColor;
  }

  .requested-by {
    padding: 10px 20px;
  }
  .for-application,
  .description {
    display: flex;
    align-items: center;
    label {
      width: 30%;
      color: $headerBGLight;
      font-size: 13px;
    }
    p {
      color: $headerBGLight;
    }
  }
}

.share-cards {
  margin-top: 12px;
  max-height: 30vh;
  min-height: 220px;
  overflow-y: auto;
  color: $primaryColor;
  ::-webkit-scrollbar {
    display: none;
  }
}

.share-conifg-card-container {
  margin-top: 10px;
}

.single-share-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  .share-card-left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
    .share-card-image {
      width: 20px;
      height: 20px;
      border-radius: 10%;
      //border: 1px solid grey;
    }
    .share-card-email {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      font-size: 13px;
      .primary-name {
        color: $primaryColor;
        font-size: 13px;
      }
      .secondary-name {
        color: $primaryColor;
        opacity: 0.7;
        font-size: 11px;
      }
    }
  }
  .share-card-action-native {
    margin-right: 20px;
    select {
      border-radius: 3px;
      border: 1px solid $inputEleBorderColor;
      height: $inputEleHeight;
      color: $primaryColor;
      font-family: "Hind Siliguri";
      font-size: $primaryFontSizeXS;
    }
  }
  .share-runs-config-container {
    display: flex;
    .share-runs-text {
      padding: 0 !important;
      color: $primaryColor;
      font-family: "Hind Siliguri";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 32px;
    }
  }
}
