@import "../../../styles/sass/NewThemeVariables.scss";

.clone-dialog-container {
  width: 650px;
  p {
    color: $primaryColor;
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 32px;
    padding: 5px 15px;
  }

  .dialog-config-name {
    color: $secondaryColor;
  }

  .content {
    display: flex;
    align-items: center;

    .config-name {
      width: 20%;
      color: $primaryColor;
      text-align: right;
      margin: 0px !important;
      padding-right: 5px;
      font-size: $primaryFontSizeXS;
    }

    .config-name-input-container {
      width: 80%;
      margin: 0px;
      border-radius: 3px;
      border: 1px solid $inputEleBorderColor;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: white;
      height: 24px;
      margin: 0px 15px;
      .input-label {
        width: 95%;
        font-family: "Hind Siliguri";
        width: 100%;
        box-sizing: border-box;
        font-family: "Hind Siliguri";
        border: 0px;
        color: $primaryColor;
        font-size: $primaryFontSizeXS;
      }
      .cancel-icon {
        color: $primaryColor;
        font-size: 15px;
        opacity: 0;

        &.visible {
          opacity: 1;
        }
      }
    }
  }
}
