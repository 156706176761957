@import "../../../styles/sass/NewThemeVariables.scss";

.info-icon {
  margin-left: 5px;
  margin-top: 2px;
  color: $fifthColor;
  font-size: 15px !important;
}
.ua-icon {
  margin-left: 5px;
  margin-top: 2px;
  color: $errorColor;
  font-size: 15px !important;
  // &:hover {
  //   color: $secondaryColor;
  // }
}
.name-info-container {
  display: flex;
  align-items: center;
}

input[type="file"] {
  color: $primaryColor;
}

.support-dialog-container {
  .popup-title {
    color: $primaryColor;
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: bolder;
    font-size: 15px;
    line-height: 32px;
    padding: 5px 15px;
  }
  .error-msg {
    color: red;
    margin: 0px;
    margin-left: 20px;
  }
  .input-field-row-container {
    display: flex;
    margin: 20px 20px 0px;
    .input-field-sub-container {
      padding: 0px 15px;
      width: 50%;
      label {
        color: $primaryColor;
      }
      .input-label {
        width: 99%;
        height: 26px;
        border: 1px solid $inputEleBorderColor;
        color: $primaryColor;
        border-radius: $primaryBR;
        font-family: $fontFamily;
      }
    }
  }
  .input-field-container {
    margin: 20px;
    padding: 0px 15px;
    label {
      color: $primaryColor;
    }
    .input-file-selection {
      display: flex;
    }
    .input-label {
      width: 99%;
      height: 26px;
      border: 1px solid $inputEleBorderColor;
      color: $primaryColor;
      border-radius: $primaryBR;
      font-family: $fontFamily;
    }
    .input-textarea {
      resize: none;
      height: 70px;
      width: 99%;
      border: 1px solid $inputEleBorderColor;
      color: $primaryColor;
      border-radius: $primaryBR;
      font-family: $fontFamily;
    }
    .cancel-icon {
      color: $primaryColor;
      font-size: 15px;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }
}

.form-action-btn {
  display: flex;
  justify-content: flex-end;
  margin: 0px 20px;
  border-top: none !important;
  padding: 0px 15px;
}

.selected-file-container {
  display: flex;
  margin-left: 5px;
  flex-wrap: wrap;
}

.input-file-label {
  display: flex;
  align-items: center;
  margin-top: 5px;
  width: 124px;
  span {
    background-color: #cecece;
    display: flex;
    align-items: center;
    border-radius: 2px;
    margin-right: 6px;
    padding: 0 5px;
  }
}

#input-file {
  visibility: hidden;
  width: 0px;
  position: absolute;
}

.single-file-container {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.input-fields-container {
  height: 562px;
  max-height: 600px;
  overflow: auto;
}
