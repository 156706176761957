.target-runs-conatiner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  .configs-container {
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .run_id {
      width: 50%;
      label {
        width: 22% !important;
      }
      .single-select-dropdown {
        width: 79% !important;
      }
    }

    .target_run_id {
      width: 50%;
      label {
        width: 22% !important;
        font-size: 13px;
      }
      .single-select-dropdown {
        width: 79% !important;
      }
    }

    .dayCat {
      width: 50%;
      label {
        width: 23% !important;
      }
      select {
        width: 79% !important;
      }
    }
  }
}
.propensity-target-runs-conatiner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  .configs-container {
    display: flex;
    // justify-content: space-between;
    align-items: center;

    .run_id {
      width: 50%;
      label {
        width: 22% !important;
      }
      .single-select-dropdown {
        width: 79% !important;
      }
    }

    .target_run_id {
      width: 50%;
      label {
        width: 22% !important;
        font-size: 13px;
      }
      .single-select-dropdown {
        width: 79% !important;
      }
    }

    .dayCat {
      width: 50%;
      label {
        width: 23% !important;
      }
      select {
        width: 79% !important;
      }
    }
  }
  .padding-container {
    padding: 10px;
  }
}
.padding-container {
  padding: 10px;
}
