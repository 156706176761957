.user-info-name {
  border-bottom: 1px solid#EAEAEA;
  padding: 10px 12px;
  font-size: 14px;
  .user-info-text {
    margin: 0px;
  }
  .user-info-sub-text {
    margin: 0px;
  }
}

.user-admin-panel-container {
  padding: 10px 12px;
  font-size: 14px;
  border-bottom: 1px solid#EAEAEA;
  .admin-panel-text {
    margin: 0px;
    cursor: pointer;
  }
}

.user-info-sign-out {
  padding: 10px 12px;
  font-size: 14px;
  .user-info-sign-out-text {
    margin: 0px;
    cursor: pointer;
  }
}
