@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round");

:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px !important;
  outline: -moz-focus-ring-color auto 0px !important;
}

:root {
  /* Body */
  --fontFamily: "Hind Siliguri";

  /* common color */
  /* --primary-color-light: #46596a;
  --primary-color-dark: #cdd4da;
  --secondary-color-light: #eb367f;
  --secondary-color-dark: #c7acb5;
  --tertiary-color-light: #25b1e6;
  --tertiary-color-dark: #7baabb;
  --forth-color-light: #4eafb3;
  --forth-color-dark: #8fafaf;
  --text-color-light: #46596a;
  --text-color-dark: white; */

  /* common */
  --border-radius: 4px;

  /* common font size */
  --font-size-XXS: 10px;
  --font-size-XS: 12px;
  --font-size-S: 14px;
  --font-size-M: 15px;
  --font-size-L: 16px;
  --font-size-XL: 18px;
  --font-size-XXL: 20px;

  /* Button */
  --button-padding: 5px 15px;
  --primary-textcolor-light: #46596a;
  --primary-textcolor-dark: #ffffff;
  --secondary-textcolor-light: #4eafb3;
  --secondary-textcolor-dark: #ffffff;
  /* Outlined Button */
  --outlined-primary-border-light: 1px solid #46596a;
  --outlined-primary-border-dark: 1px solid #4eafb3;
  --outlined-secondary-border-light: 1px solid #4eafb3;
  --outlined-secondary-border-dark: 1px solid #46596a;
  --outlined-primary-textcolor-light: #46596a;
  --outlined-primary-textcolor-dark: #4eafb3;
  --outlined-secondary-textcolor-light: #4eafb3;
  --outlined-secondary-textcolor-dark: #46596a;
  /* Contained Button */
  --contained-primary-textcolor-light: #ffffff;
  --contained-primary-textcolor-dark: #4eafb3;
  --contained-secondary-textcolor-light: #ffffff;
  --contained-secondary-textcolor-dark: #46596a;
  --contained-primary-bgcolor-light: #46596a;
  --contained-primary-bgcolor-dark: #ffffff;
  --contained-secondary-bgcolor-light: #4eafb3;
  --contained-secondary-bgcolor-dark: #ffffff;

  /* Autocomplete */
  --list-element-color: white;
  --autocomplete-primary-textcolor-light: #46596a;
  --autocomplete-primary-textcolor-dark: #4eafb3;
  --autocomplete-secondary-textcolor-light: #ffffff;
  --autocomplete-secondary-textcolor-dark: #46596a;
  --autocomplete-primary-bgcolor-light: #f3f3f3;
  --autocomplete-primary-bgcolor-dark: #928d8d;
  --autocomplete-secondary-bgcolor-light: #4eafb3;
  --autocomplete-secondary-bgcolor-dark: #928d8d;
  /* Selected List */
  --autocomplete-primary-selected-bgcolor-light: #4eafb3;
  --autocomplete-primary-selected-bgcolor-dark: #46596a;
  --autocomplete-secondary-selected-bgcolor-light: #46596a;
  --autocomplete-secondary-selected-bgcolor-dark: #4eafb3;

  /* Textfield */
  --primary-textfield-bgcolor-light: #f3f3f3;
  --primary-textfield-bgcolor-dark: #46596a;
  --primary-textfield-color-light: #46596a;
  --primary-textfield-color-dark: #f3f3f3;
  --secondary-textfield-bgcolor-light: #4eafb3;
  --secondary-textfield-bgcolor-dark: #ffffff;
  --secondary-textfield-color-light: #ffffff;
  --secondary-textfield-color-dark: #4eafb3;
  --textfield-padding: 5px;

  /* Checkbox */
  --checkbox-element-size: 18px;
  --primary-checkbox-light: #46596a;
  --primary-checkbox-dark: #ffffff;
  --secondary-checkbox-light: #4eafb3;
  --secondary-checkbox-dark: #ffffff;

  /* Radio Button */
  --radio-element-size: 15px;
  --primary-radio-light: #46596a;
  --primary-radio-dark: #ffffff;
  --secondary-radio-light: #4eafb3;
  --secondary-radio-dark: #ffffff;

  /* Loader */
  --primary-loader-color-light: #ea3592;
  --primary-loader-color-dark: #a1a1a1;
  --secondary-loader-color-light: #4eafb3;
  --secondary-loader-color-dark: #a1a1a1;

  /* Slider */
  --primary-slider-color-light: #4eafb3;
  --primary-slider-color-dark: #a1a1a1;
  --secondary-slider-color-light: #46596a;
  --secondary-slider-color-dark: #a1a1a1;

  /* Modal */
  --primary-modal-bgcolor-light: white;
  --primary-modal-bgcolor-dark: rgb(68, 65, 65);
  --primary-modal-textcolor-light: #46596a;
  --primary-modal-textcolor-dark: #f1f1f1;
  --secondary-modal-bgcolor-light: white;
  --secondary-modal-bgcolor-dark: rgb(197, 197, 197);
  --secondary-modal-textcolor-light: #4eafb3;
  --secondary-modal-textcolor-dark: #f1f1f1;

  /* Dialog */
  --primary-dialog-bgcolor-light: white;
  --primary-dialog-bgcolor-dark: #46596a;
  --primary-dialog-textcolor-light: #46596a;
  --primary-dialog-textcolor-dark: white;
  --secondary-dialog-bgcolor-light: white;
  --secondary-dialog-bgcolor-dark: #4eafb3;
  --secondary-dialog-textcolor-light: #4eafb3;
  --secondary-dialog-textcolor-dark: #f1f1f1;

  /* Popover */
  --primary-popover-bgcolor-light: white;
  --primary-popover-bgcolor-dark: #46596a;
  --primary-popover-textcolor-light: #46596a;
  --primary-popover-textcolor-dark: white;
  --secondary-popover-bgcolor-light: white;
  --secondary-popover-bgcolor-dark: #4eafb3;
  --secondary-popover-textcolor-light: #4eafb3;
  --secondary-popover-textcolor-dark: #f1f1f1;

  /* Tooltip */
  --primary-tooltip-bgcolor-light: white;
  --primary-tooltip-bgcolor-dark: #ea3592;
  --primary-tooltip-textcolor-light: #46596a;
  --primary-tooltip-textcolor-dark: #f1f1f1;
  --secondary-tooltip-bgcolor-light: white;
  --secondary-tooltip-bgcolor-dark: #ea3592;
  --secondary-tooltip-textcolor-light: #4eafb3;
  --secondary-tooltip-textcolor-dark: #f1f1f1;

  /* App */
  --app-bgcolor-light: #f9f9f9;
  --app-bgcolor-dark: #dfe1e6;

  /* Alert */
  --primary-alert-bgcolor-light: #f0f2f4;
  --primary-alert-bgcolor-dark: #46596a;
  --primary-alert-textcolor-light: #46596a;
  --primary-alert-textcolor-dark: #f1f1f1;
  --secondary-alert-bgcolor-light: #f0f2f4;
  --secondary-alert-bgcolor-dark: #4eafb3;
  --secondary-alert-textcolor-light: #4eafb3;
  --secondary-alert-textcolor-dark: #f1f1f1;

  /* Badge */
  --primary-badge-bgcolor-light: #f10000;
  --primary-badge-bgcolor-dark: #46596a;
  --secondary-badge-bgcolor-light: #4eafb3;
  --secondary-badge-bgcolor-dark: #ea3592;

  /* Select */
  --primary-select-textcolor-light: #46596a;
  --primary-select-textcolor-dark: #f1f1f1;
  --primary-select-bgcolor-light: #f1f1f1;
  --primary-select-bgcolor-dark: #46596a;
  --secondary-select-textcolor-light: #4eafb3;
  --secondary-select-textcolor-dark: #f1f1f1;
  --secondary-select-bgcolor-light: #f1f1f1;
  --secondary-select-bgcolor-dark: #4eafb3;

  /* MenuItem */
  --primary-menuitem-textcolor-light: #46596a;
  --primary-menuitem-textcolor-dark: #46596a;
  --primary-menuitem-bgcolor-light: #46596a;
  --primary-menuitem-bgcolor-dark: #46596a;
  --secondary-menuitem-textcolor-light: #4eafb3;
  --secondary-menuitem-textcolor-dark: #4eafb3;
  --secondary-menuitem-bgcolor-light: #4eafb3;
  --secondary-menuitem-bgcolor-dark: #4eafb3;
  --primary-menuitem-selected-textcolor-light: #4eafb3;
  --primary-menuitem-selected-textcolor-dark: #46596a;
  --secondary-menuitem-selected-textcolor-light: #46596a;
  --secondary-menuitem-selected-textcolor-dark: #4eafb3;

  /* Drawer */
  --primary-drawer-textcolor-light: #46596a;
  --primary-drawer-textcolor-dark: #f1f1f1;
  --primary-drawer-bgcolor-light: #f1f1f1;
  --primary-drawer-bgcolor-dark: #46596a;
  --secondary-drawer-textcolor-light: #4eafb3;
  --secondary-drawer-textcolor-dark: #f1f1f1;
  --secondary-drawer-bgcolor-light: #f1f1f1;
  --secondary-drawer-bgcolor-dark: #4eafb3;

  /* Menu */
  --primary-menu-bgcolor-light: #f1f1f1;
  --primary-menu-bgcolor-dark: #46596a;
  --secondary-menu-bgcolor-light: #f1f1f1;
  --secondary-menu-bgcolor-dark: #4eafb3;

  /* Rating */
  --primary-rating-bgcolor-light: #46596a;
  --primary-rating-bgcolor-dark: #f1f1f1;
  --secondary-rating-bgcolor-light: #4eafb3;
  --secondary-rating-bgcolor-dark: #f1f1f1;

  /* Avatar */
  --avatar-primary-bgcolor-light: #46596a;
  --avatar-primary-bgcolor-dark: #f1f1f1;
  --avatar-primary-textcolor-light: #f1f1f1;
  --avatar-primary-textcolor-dark: #46596a;
  --avatar-secondary-bgcolor-light: #4eafb3;
  --avatar-secondary-bgcolor-dark: #f1f1f1;
  --avatar-secondary-textcolor-light: #f1f1f1;
  --avatar-secondary-textcolor-dark: #4eafb3;

  /* Switch */
  --switch-primary-light: #eb367f;
  --switch-primary-dark: #e494b4;
  --switch-tertiary-light: #25b1e6;
  --switch-tertiary-dark: #7ec5df;
  --switch-secondary-light: #46596a;
  --switch-secondary-dark: #899cac;
  --switch-disabled-light: #b1b1b1;
  --switch-disabled-dark: #f1f1f1;

  /* Tabs */
  --primary-tabs-bgcolor-light: #eb367f;
  --primary-tabs-bgcolor-dark: #e770a0;
  --secondary-tabs-bgcolor-light: #4eafb3;
  --secondary-tabs-bgcolor-dark: #8ddee0;
  --primary-tabs-textcolor-light: #eb367f;
  --primary-tabs-textcolor-dark: #e770a0;
  --secondary-tabs-textcolor-light: #4eafb3;
  --secondary-tabs-textcolor-dark: #8ddee0;

  /* Accordion */
  --accordion-bgcolor-primary-light: #f9f9f9;
  --accordion-bgcolor-primary-dark: #46596a;
  --accordion-textcolor-primary-light: #46596a;
  --accordion-textcolor-primary-dark: #f1f1f1;
  --accordion-bgcolor-secondary-light: #f9f9f9;
  --accordion-bgcolor-secondary-dark: #4eafb3;
  --accordion-textcolor-secondary-light: #4eafb3;
  --accordion-textcolor-secondary-dark: #f1f1f1;
  --accordion-bordercolor-light: #d1d1d1;
  --accordion-bordercolor-dark: #f1f1f1;
  --accordion-summary-minHeight: 32px;
  --accordion-summary-maxHeight: 40px;
  --accordion-details-minHeight: 32px;
  --accordion-details-maxHeight: 250px;
  --accordion-padding: 16px;
  --font-size-accordion: 13px;

  /* Breadcrumbs */
  --breadcrumbs-primary-textcolor-light: #eb367f;
  --breadcrumbs-primary-textcolor-dark: #46596a;
  --breadcrumbs-primary-active-textcolor-light: #46596a;
  --breadcrumbs-primary-active-textcolor-dark: #eb367f;
  --breadcrumbs-secondary-textcolor-light: #eb367f;
  --breadcrumbs-secondary-textcolor-dark: #4eafb3;
  --breadcrumbs-secondary-active-textcolor-light: #4eafb3;
  --breadcrumbs-secondary-active-textcolor-dark: #eb367f;

  /* Chip */
  --chip-primary-textcolor-light: #f1f1f1;
  --chip-primary-textcolor-dark: #fcecec;
  --chip-secondary-textcolor-light: #f1f1f1;
  --chip-secondary-textcolor-dark: #fcecec;
  --chip-primary-bgcolor-light: #4eafb3;
  --chip-primary-bgcolor-dark: #96dcdf;
  --chip-secondary-bgcolor-light: #46596a;
  --chip-secondary-bgcolor-dark: #91a6b8;
  --outlined-chip-primary-textcolor-light: #46596a;
  --outlined-chip-primary-textcolor-dark: #4eafb3;
  --outlined-chip-secondary-textcolor-light: #4eafb3;
  --outlined-chip-secondary-textcolor-dark: #46596a;

  /* DatePicker */
  --datepicker-primary-textcolor-light: #46596a;
  --datepicker-primary-textcolor-dark: #aaa8a8;
  --datepicker-secondary-textcolor-light: #4eafb3;
  --datepicker-secondary-textcolor-dark: #f1f1f1;

  /* Table */
  --primary-table-textcolor-light: #46596a;
  --primary-table-textcolor-dark: #f1f1f1;
  --secondary-table-textcolor-light: #4eafb3;
  --secondary-table-textcolor-dark: #7ec5df;
  /* Table Header */
  --primary-table-header-textcolor-light: #46596a;
  --primary-table-header-textcolor-dark: #f1f1f1;
  --secondary-table-header-textcolor-light: #4eafb3;
  --secondary-table-header-textcolor-dark: #7ec5df;
  --primary-table-header-bgcolor-light: #e6e8ed;
  --primary-table-header-bgcolor-dark: #a19d9d;
  --secondary-table-header-bgcolor-light: #e6e8ed;
  --secondary-table-header-bgcolor-dark: #a19d9d;

  /* Pagination */
  --primary-pagination-textcolor-light: #46596a;
  --primary-pagination-textcolor-dark: #f1f1f1;
  --secondary-pagination-textcolor-light: #4eafb3;
  --secondary-pagination-textcolor-dark: #7ec5df;
}
