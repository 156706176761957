@import "../../../styles/sass/NewThemeVariables.scss";

.disabled-pointer {
  pointer-events: none;
}
.new-kpi {
  color: #46596a80;
}
.groups-dialog-container {
  padding: 15px;
  height: 450px;
  p {
    color: $primaryColor;
    font-family: "Hind Siliguri";
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 32px;
    padding: 5px 15px;
  }
  .dialog-input-search {
    width: 92%;
    border-radius: $primaryBR;
    background-color: $inputSearchBGColor;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 24px;
    padding: 5px 10px;
    margin: 10px;

    .input-label {
      width: 95%;
      font-family: "Hind Siliguri";
      background-color: $inputSearchBGColor;
      border: none;
    }
    .search-icon {
      color: $primaryColor;
      font-size: 15px;
    }
    .cancel-icon {
      color: $primaryColor;
      font-size: 15px;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }
  .users-list-container-dialog {
    margin-top: 12px;
    max-height: 30vh;
    min-height: 220px;
    overflow-y: auto;
    color: #46596a;
  }
}

.admin-group-name,
.admin-group-id,
.admin-user-name {
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin-created-by,
.admin-modified-by,
.admin-user-modified-by,
.admin-user-created-by {
  white-space: nowrap;
  // width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.group-selections {
  display: flex;
  height: calc(100vh - 350px);
}

.users-selections {
  display: flex;
  height: 100%;
}

.show-more {
  pointer-events: all !important;
  color: $primaryColor;
  font-size: $primaryFontSizeS;
  margin-left: 15px;
  cursor: pointer;
}

.group-details-popup-btn {
  display: flex;
  justify-content: flex-end;
  margin: 7px 15px;
  border-top: none !important;
  padding: 10px 0px;
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.top-panel {
  height: calc(100% - 78px);
  overflow-y: auto;
  margin: 0px -30px 0px 0px;
  padding: 0px 30px 0px 0px;
}

.admin-bottom-panel {
  height: 30px;
  margin: 0px -30px;
  .user-actions-buttons {
    background-color: $appBGColorLight;
    padding: 10px 35px;
    display: flex;
    justify-content: flex-end;
  }
  .group-actions-buttons {
    background-color: $appBGColorLight;
    padding: 10px 35px;
    display: flex;
    justify-content: flex-end;
  }
}

.no-user-data {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;
  img {
    height: 20vh;
    width: 100%;
  }
  .no-data {
    color: $primaryColor;
    text-align: center;
    font-size: 14px;
  }
}
.admin-panel-user-details-container {
  border-radius: $primaryBRLarge;
  background-color: #fbfbff;
  .admin-panel-user-details-sub-container {
    display: flex;
    //width: 100%;
    padding: 10px;
    .groups-selected-container {
      width: 100%;
      .title {
        //   width: 5%;
        margin: 0px 15px 0px 0px;
        color: $primaryColor;
        font-size: $primaryFontSizeS;
        font-weight: 600;
      }
    }
    .user-name-container {
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      padding: 5px;
      width: 33.33%;
      .title {
        //   width: 5%;
        margin: 0px 15px 0px 0px;
        color: $primaryColor;
        font-size: $primaryFontSizeS;
        font-weight: 600;
      }
      .name-config {
        display: flex;
        background: white;
        padding: 5px;
        //   width: 33.33%;
        color: $primaryColor;
        border: 1px solid $inputEleBorderColor;
        border-radius: 3px;
        height: 20px;
        input[type="text"] {
          width: 100%;
          background: white;
          border: none;
          box-sizing: border-box;
          font-family: "Hind Siliguri";
          color: $primaryColor;
          // padding: 10px 0px !important;
        }
        .config-name {
          display: flex;
          overflow: visible;
          color: #989494;
        }
      }
    }
  }
}

.admin-panel-group-details-container {
  display: flex;
  //flex-direction: column;
  //width: 100%;
  padding: 10px;
  border-radius: $primaryBRLarge;
  background-color: #fbfbff;
  .groups-detail-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 40%;
    .title {
      //width: 10%;
      margin: 0px 20px 0px 0px;
      color: $primaryColor;
      font-size: $primaryFontSizeS;
      font-weight: 600;
    }
    .group-detail-config {
      display: flex;
      background: white;
      padding: 5px;
      width: 90%;
      color: $primaryColor;
      border: 1px solid $inputEleBorderColor;
      border-radius: 3px;
      height: 20px;
      input[type="text"] {
        width: 100%;
        background: white;
        border: none;
        box-sizing: border-box;
        font-family: "Hind Siliguri";
        color: $primaryColor;
        padding: 10px 0px !important;
      }
      .config-name {
        display: flex;
        overflow: visible;
        color: #989494;
      }
    }
  }
  .users-list-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 10%;
    .title {
      margin: 0px 20px 0px 0px;
      color: $primaryColor;
      font-size: $primaryFontSizeS;
      font-weight: 600;
    }
    .selected-users-container {
      font-size: $primaryFontSizeS;
      color: $primaryColor;
      font-weight: 600;
      .link-show-list {
        color: $secondaryColor;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}

.no-users-assigned {
  font-size: 14px;
  text-align: center;
  padding: 2%;
  font-family: "Hind Siliguri";
  .no-user-msg {
    font-weight: normal;
  }
}

.single-name-entry {
  display: flex;
  align-items: center;

  p {
    margin: 0px;
    width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: normal;
  }
}

.option-users {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  .user-detail {
    display: flex;
    flex-direction: column;
    padding-left: 6%;
  }
}

// .input-search {
//   width: 52%;
//   border-radius: $primaryBR;
//   background-color: $inputSearchBGColor;
//   display: flex;
//   align-items: center;
//   justify-content: space-around;
//   height: 24px;
//   padding: 5px 10px;
//   margin: 10px;

//   .input-label {
//     width: 95%;
//     font-family: "Hind Siliguri";
//     background-color: $inputSearchBGColor;
//     border: none;
//   }
//   .search-icon {
//     color: $primaryColor;
//     font-size: 15px;
//   }
//   .cancel-icon {
//     color: $primaryColor;
//     font-size: 15px;
//     opacity: 0;

//     &.visible {
//       opacity: 1;
//     }
//   }
// }
