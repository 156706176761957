@import "../../../styles/sass/NewThemeVariables.scss";

.user_metrics_report_container {
  height: 94%;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  .user_metrics_report {
    height: 90%;
    width: 98%;
    border: none;
  }
}
