@import "../../../../styles/sass/NewThemeVariables.scss";

.other-option-text {
  font-size: $primaryFontSizeS;
  color: $primaryColor;
  padding: 6px 16px;
  margin: 0px;
}

.arrow-circle-right-icon {
  font-size: $iconXXL !important;
  margin-right: 10px;
}

.config-settings-container {
  display: flex;
  .more-vert-icon {
    font-size: $iconXXL;
  }
}

.tags-action-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 460px;
  height: 250px;
  padding: 20px;
  .selected-tags-container {
    margin-top: 10px;
    .chip-styling {
      width: 100px;
    }
  }
  .tag-btn {
    display: flex;
    justify-content: flex-end;
  }
  .no-tags {
    color: #465a69;
    text-align: center;
  }
}

.visible {
  opacity: 1;
}

.hidden {
  font-size: 0px !important;
  opacity: 0;
}

.config-additional-settings {
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 3px;
  position: absolute;
  right: 38px;
  top: 80px;
  font-size: $primaryFontSizeS;
  .update-config-tags {
    background-color: $secondaryColor;
    border: 1px solid transparent;
    border-radius: 18px;
    height: 28px;
    color: $primaryFontColorDark;
    display: flex;
    font-size: $primaryFontSizeS;
    align-items: center;
    justify-content: space-around;
    width: 110px;
    cursor: default;
    .info-alt-icon {
      font-size: 18px;
      margin-left: 5px;
      border-left: 1px solid #f1f1f1;
      padding-left: 10px;
    }
  }
  .add-tags-container {
    color: $secondaryColor;
    text-decoration: underline;
    width: fit-content;
    margin-right: 10px;
    //margin-bottom: 5px;
    font-weight: 600;
    cursor: pointer;
  }
}

.add-new-config {
  font-size: $primaryFontSizeS;
  color: $primaryColor;
  height: 100%;
  box-sizing: border-box;
  height: calc(100% - 48px);

  .top-panel {
    height: calc(100% - 30px);
    overflow-y: auto;
    margin: 0px -30px 0px 0px;
    padding: 0px 30px 0px 0px;
    position: relative;
  }

  .no-bottom-panel {
    height: calc(100%);
    overflow-y: auto;
    margin: 0px -30px 0px 0px;
    padding: 0px 30px 0px 0px;
    // position: relative;
  }

  .bottom-panel {
    height: 30px;
    margin: 0px -30px 0px -30px;
  }

  .disabled-pointer {
    pointer-events: none;
  }

  p {
    margin: 0px;
    &.config-name {
      margin-right: 10px;
    }
  }

  .toggle-icon {
    // float: right;
    // right: 10px;
    // top: -35px;
    // position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-align: right;
    text-transform: capitalize;
    color: $primaryColor;
    position: absolute;
    right: 35px;
    // top: 12px;
    margin-top: 5px;
    label {
      cursor: pointer;
    }
  }

  .add-config {
    background: $appBGColor;
    //border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px 12px;

    .collapsed-config {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      //padding-bottom: 10px;
      justify-content: space-between;
      .update-global-params {
        margin: 1.5rem 1rem 0 1rem;
        .pending-changes-warning {
          white-space: nowrap;
          font-size: 11px;
          color: red;
          margin-top: 2px;
        }
      }
      .input-item-container {
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        padding: 10px;
        .datasets-selections-local-parameters-row {
          display: flex !important;
          flex-direction: row;
          justify-content: space-between;
          flex-grow: 1;
          .input-item {
            .country-brand-width {
              width: 275px !important;
            }
          }
        }
        .country-brand-container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
          .input-item {
            // margin-right: 60px;
            flex: 0 1 45%;
          }
        }
      }
      .name {
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        // background-color: white;
        padding: 5px 5px 5px 10px;
        // padding-left: 5px;
        // padding-right: 5px;
        // float: left;
        width: 50%;

        .config-name-label-container {
          display: flex;
          .title {
            // width: 5%;
            margin: 0px 15px 0px 0px;
            color: $primaryColor;
            font-size: $primaryFontSizeS;
            font-weight: 600;
          }
          .name-chip {
            background-color: $secondaryColor;
            border: 1px solid transparent;
            border-radius: 18px;
            height: 20px;
            color: $primaryFontColorDark;
            cursor: pointer;
            display: flex;
            font-size: 11px;
            align-items: center;
            justify-content: space-around;
            width: 90px;
            cursor: default;
          }
        }

        .name-config {
          display: flex;
          background: white;
          padding: 5px;
          // width: 609px;
          color: $primaryColor;
          border: 1px solid $inputEleBorderColor;
          border-radius: 3px;
          height: 20px;
          input[type="text"] {
            width: 85%;
            background: $primaryFontColorDark;
            border: none;
            box-sizing: border-box;
            font-family: "Hind Siliguri";
            color: $primaryColor;
          }
          .config-name {
            display: flex;
            overflow: visible;
            color: #989494;
          }
        }

        .content {
          width: 92%;

          .config-name {
            width: 20%;
            text-align: right;
            margin: 0px !important;
            padding-right: 5px;
          }

          .config-name-input-container {
            width: 50%;
            margin: 0px;

            input {
              width: 100%;
              box-sizing: border-box;
              font-family: "Hind Siliguri";
            }
          }
        }

        .global-params-toggle-icon {
          width: 3%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $appBGColor;
          height: 24px;
          margin: 0px 0px 0px 4px;
          border-radius: 4px;
          border: 1px solid $inputEleBorderColor;
        }
      }
      .type-of-run {
        display: flex;
        flex-direction: column;
        // padding: 5px;
        // margin-left: 73px;
        padding: 0 10px;
        flex: 0 1 42%;
        justify-content: space-evenly;
        .title {
          margin: 0px 15px 0px 0px;
          color: #46596a;
          font-size: 13px;
          font-weight: 600;
        }
      }
    }
    .globalParameter-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .schedule-input-field-container {
      // margin: 10px 20px 10px 10px;
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      .run-frequency-container {
        display: flex;
        flex: 0 1 50%;
        padding: 10px;
        justify-content: space-between;
      }
      label {
        color: $primaryColor;
        margin-bottom: 5px;

        font-weight: 600;
        text-transform: capitalize;
      }
      .input-label {
        width: 100%;
        height: 26px;
        border: 1px solid $inputEleBorderColor;
        color: $primaryColor;
        border-radius: $primaryBR;
        font-family: $fontFamily;
        font-size: $primaryFontSizeXS;
        font-weight: 400;
        &.disabled {
          color: $disabledFontColor;
        }
      }
      .input-field-row-container {
        display: flex;
        justify-content: space-between;
        // width: 275px;
        flex: 0 1 45%;
        .input-field-sub-container {
          // width: 76%;
          width: 100%;
          // margin-right: 10px;
          &.no-label {
            display: flex;
            align-items: flex-end;
            // justify-content: end;
            // margin-left: 17px;
            .input-text-box {
              width: 100%;
              // max-width: 275px;
            }
          }
          .scoring-week-selection {
            display: flex;
            justify-content: space-between;
          }
          label {
            color: $primaryColor;
          }
          .input-label {
            width: 99%;
            height: 26px;
            border: 1px solid $inputEleBorderColor;
            color: $primaryColor;
            border-radius: $primaryBR;
          }
          input[type="date"] {
            border-radius: 3px;
            border: 1px solid $inputEleBorderColor;
            height: 30px;
            width: 100%;
            color: $primaryColor;
            box-sizing: border-box;
            padding-left: $inputLeftSpacing;
            text-transform: uppercase;
            font-family: "Hind Siliguri";
            font-size: $primaryFontSizeXS;
            margin-left: -1px;
          }
        }
        .input-field-delete-container,
        .input-field-pending-container {
          display: flex;
          align-items: flex-end;
          margin-bottom: 2px;
          padding: 5px 10px;
          // width: 70%;
          .icon-disabled {
            pointer-events: none;
            color: #949494 !important;
          }
        }
        .input-field-pending-container {
          align-items: center;
        }
      }
    }
  }

  .user-input {
    background-color: $appBGColorLight;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px;
    height: 70%;
    overflow: hidden;

    &.colorless {
      background-color: white;
      padding: 0px;
    }

    .name {
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: nowrap;
      padding-left: 5px;
      padding-right: 5px;
      width: 70%;

      .title {
        width: 5%;
        margin: 0px 15px 0px 0px;
        color: $primaryColor;
        font-size: $primaryFontSizeS;
        font-weight: 600;
      }

      .name-config {
        color: red;
        padding: 5px;
      }

      .content {
        width: 92%;

        .config-name {
          width: 20%;
          text-align: right;
          margin: 0px !important;
          padding-right: 5px;
        }

        .config-name-input-container {
          width: 50%;
          margin: 0px;

          input {
            width: 100%;
            box-sizing: border-box;
            font-family: "Hind Siliguri";
          }
        }
      }

      .global-params-toggle-icon {
        width: 3%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $appBGColor;
        height: 24px;
        margin: 0px 0px 0px 4px;
        border-radius: 4px;
        border: 1px solid $inputEleBorderColor;
      }
    }

    .title {
      margin: 0px 0px 5px 0px;
      color: $secondaryColor;
      font-size: $primaryFontSizeS;
      font-weight: 300;
    }
    .content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      &.name {
        width: 94%;
      }

      &.region-granularity {
        margin-top: 10px;

        .region-granularity-global {
          margin-top: 6.5px;
        }

        .test-control-container {
          display: flex;
          flex-direction: row;
          width: 100%;

          .test-control {
            display: flex;
            align-items: center;
            margin: 5px 0px;

            .title {
              width: 65px;
              margin: 0px 20px;
              color: $primaryColor;
              text-align: right;
            }
            .multi-select-container {
              width: 90%;
            }
          }
        }
      }

      .loader-center {
        width: 100%;
        display: flex;
        justify-content: center;
      }

      &.region-selection {
        align-items: flex-start;
        flex-direction: column;
        padding: 10px;
        background: $appBGColor;
        width: inherit;
        // border: 1px solid $sidenavBorderColor;
        border-radius: $primaryBR;
        //padding-top: 12px;
        .test-control-global {
          flex-direction: column;
          .test-control-global-select {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
          label {
            margin-right: 5px;
            color: $primaryColor;
            font-family: Hind Siliguri;
            font-style: normal;
            font-weight: 600;
            font-size: $primaryFontSizeS;
          }
        }
      }

      &.sales-params {
        display: flex;
        padding-top: 15px;

        & .input-item {
          display: flex;
          flex-direction: row;
          margin-right: 0px;
          width: 100%;

          label {
            width: 18%;
            font-size: $primaryFontSizeS;
          }
        }
      }

      &.region-local {
        .region-granularity-global {
          margin-top: 6.5px;
        }

        .test-control-container {
          display: flex;
          flex-direction: row;
          width: 100%;

          .test-control {
            display: flex;
            align-items: flex-end;
            align-items: center;
            margin: 8px 0px;
            width: 100%;

            .title {
              width: 4%;
              margin: 0px 20px;
              color: $primaryColor;
              text-align: right;
            }
            .multi-select-container {
              width: 96%;
            }
          }
        }
      }

      .input-item-container {
        display: flex !important;
        // align-items: center;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        padding: 10px;
      }
    }
  }

  .info-message {
    text-align: center;
    margin-top: 5px !important;
  }

  .user-actions-buttons {
    background-color: $appBGColorLight;
    padding: 10px 35px;
  }

  .bold {
    font-weight: 600 !important;
  }

  .header-text {
    font-size: $primaryFontSizeS !important;
  }
  .upload-audience-label {
    display: flex;
    align-items: center;
    height: 36px;
  }
  .upload-audience-label-propensity {
    display: flex;
    align-items: center;
  }
  .upload-audience-field,
  .upload-audience-field-propensity {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-left: 1rem;
    .upload-audience-checkbox {
      display: flex;
      align-items: center;
      .upload-audience-checkbox-label {
        width: 1rem;
      }
    }
  }
  .upload-audience-field-propensity {
    grid-template-columns: repeat(8, 1fr);
    margin-left: 0;
  }
  .selection-text {
    font-size: $primaryFontSizeXS !important;
  }
}

.kpi-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .kpi-detail-row {
    display: flex;
    .kpi-name {
      width: 20%;
      display: flex;
      flex-direction: column;
      margin-right: 5px;
      .input-label {
        font-family: $fontFamily;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $inputEleBorderColor;
        border-radius: $primaryBR;
        color: $primaryColor !important;
        font-size: $primaryFontSizeXS;
        background-color: white !important;
        padding: 4px 6px;
      }
    }
    .su-limit-info,
    .sl-limit-info,
    .factor-info,
    .limit-info {
      width: 15%;
      margin-right: 5px;
      .input-info {
        color: $primaryColor;
        font-size: 10px;
        opacity: 0.7;
      }
      .input-label {
        font-family: $fontFamily;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid $inputEleBorderColor;
        border-radius: $primaryBR;
        color: $primaryColor;
        font-size: $primaryFontSizeXS;
        padding: 4px 6px;
      }
    }
    .saturation-flag-info {
      width: 20%;
    }
  }
}

.ae-scheduler-note {
  color: $secondaryColor;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-top: 3px;
  position: absolute;
  right: 131px;
  top: 80px;
  font-size: 13px;
}
