@import "../../../../styles/sass/NewThemeVariables.scss";

.users-selection-content {
  height: calc(100% - 90px);
  &.no-data-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    min-height: $noDataAvailableMinHeight !important;
    max-height: $noDataAvailableMaxHeight !important;

    .no-datasets-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        height: 130px;
        width: 130px;
      }

      .no-datasets-icon {
        font-size: 30px;
        cursor: pointer;
        color: $secondaryColor;
      }
    }
  }

  .users-selections-column {
    height: inherit;
  }
}

.selected-card {
  background-color: #4eafb3;
  color: #fff;
  padding: 2px 2px 2px 10px;
  border-radius: 4px;
  .MuiIconButton-label {
    color: #fff;
    font-family: Hind Siliguri !important;
    font-size: 14px;
  }
}

.not-selected-card {
  padding: 2px 2px 2px 10px;
  font-size: 14px;
  color: $primaryColor;
}

.selected-group-card {
  background-color: #4eafb3;
  color: #fff;
  padding: 2px 2px 5px 10px;
  border-radius: 4px;
  margin: 0px 10px;
  .kpi-checkbox-container {
    .group-row {
      .group-selected-icon {
        width: 20px;
        font-size: 15px;
        color: white;
      }
    }
  }
}

.not-selected-group-card {
  padding: 2px 2px 5px 10px;
  margin: 0px 10px;
  font-size: 14px;
  color: $primaryColor;
  .kpi-checkbox-container {
    .group-row {
      .group-selected-icon {
        width: 20px;
        font-size: 15px;
        color: #4eafb3;
      }
    }
  }
}

.groups-info-container {
  max-height: calc(100% - 65px);
  overflow-y: auto;

  .kpi-checkbox-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .group-row {
      display: flex;
      align-items: center;
      .group-selected-icon {
        width: 20px;
        font-size: 15px;
      }
    }
    label {
      padding: 5px 5px 0px 5px;
      font-size: 14px;
      font-family: Hind Siliguri !important;
    }
    .label-clickable {
      cursor: pointer;
      margin: 4px 0px;
      font-size: 14px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 150px;
    }
  }
}
.search-active {
  max-height: calc(100% - 115px);
}
.groups-info-search {
  padding: 0 10px;
  padding-bottom: 20px;
  .input-search {
    width: calc(100% - 20px);
    border-radius: $primaryBR;
    background-color: $inputSearchBGColor;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 24px;
    padding: 5px 10px;

    .input-label {
      width: 95%;
      font-family: "Hind Siliguri";
      background-color: $inputSearchBGColor;
    }
    .search-icon {
      color: $primaryColor;
      font-size: 15px;
    }
    .cancel-icon {
      color: $primaryColor;
      font-size: 15px;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }
  .input-search .input-label {
    width: 100%;
    float: left;
    height: 100%;
    border: 0px;
    color: $primaryColor;
    padding-left: 10px;
  }
}
.users-parameter-div {
  background-color: #e6e8ed;
  height: 45px;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 1%;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .users-parameter-text {
    padding-left: 10px;
    width: fit-content;
    height: 25px;
    margin: 0px;
    position: relative;
    background: white;
    color: $primaryColor;
    text-align: center;
    font-size: 14px;
    font-family: Hind Siliguri !important;
  }
  .users-parameter-text:before {
    content: "";
    position: absolute;
    right: -15px;
    height: 0;
    border-left: 15px solid white;
    border-top: 11px solid transparent;
    border-bottom: 14px solid transparent;
  }
  .users-parameter-icons {
    color: $primaryColor;
    display: flex;
    .material-icons-outlined,
    .active {
      font-size: 20px;
      padding: 2px;
    }
    .active {
      background-color: #4eafb3;
      color: #fff;
      border-radius: 3px;
    }
  }
}

.users-selections-group-row {
  width: 320px;
  background-color: #fcfcfe;
}

.users-selections-apps-row {
  width: 280px;
  margin-left: 10px;
  background-color: #fcfcfe;
}

.users-selections-access-list-row {
  width: 280px;
  margin-left: 10px;
  background-color: #fcfcfe;
}

.users-country-selections-row {
  margin-left: 10px;
  &.feature-list {
    width: calc(100% - 565px) !important;
    .users-parameter-div {
      height: 40px;
    }
  }
  &.country-list {
    width: calc(100% - 862px);
  }
  background-color: #fcfcfe;
  border-radius: 6px;
  //margin-right: 10px;
  .group-details-data-container {
    position: relative;
    height: calc(100vh - 250) !important;
    //padding: 10px 10px 10px 20px;
  }
}

.users-brand-selections-row {
  width: calc(100% - 862px);
  background-color: #fcfcfe;
  border-radius: 6px;
  margin-left: 10px;
  color: $primaryColor;
  .group-details-data-container {
    position: relative;
    height: calc(100vh - 250) !important;
    //padding: 10px 10px 10px 20px;
  }
}
