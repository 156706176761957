@import "../../../styles/sass/NewThemeVariables.scss";

.work-in-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // height: 300px;
  padding: 8%;

  .wip-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20vh;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .work-in-progress-text {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: $primaryColor;
  }
}
