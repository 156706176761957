@import "../../../../styles/sass/NewThemeVariables.scss";

.run {
  display: flex;
  flex-direction: row;
  align-items: center;

  .run-name-input-container {
    width: 1000px;
    input {
      width: 100%;
    }
  }
  .toggle {
    background-color: $appBGColor;
    padding: 3px;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primaryColor;

    .rotate {
      transform: rotate(180deg);
    }
  }
}

.global-params {
  width: 100%;
  background-color: $appBGColor;
}
