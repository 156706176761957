@import "../../../styles/sass/NewThemeVariables.scss";

.schedule-config-dialog-container {
  //   max-height: calc(100% - 100px);
  //   min-height: 200px;
  min-width: 800px;
  font-size: $primaryFontSizeXS;
  font-family: $fontFamily;
  .popup-title {
    color: $primaryColor;
    font-style: normal;
    font-weight: bolder;
    font-size: 15px;
    line-height: 32px;
    margin: 15px 20px;
  }
  .input-field-row-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 20px 0px;
    .input-field-sub-container {
      width: 48%;
      &.no-label {
        display: flex;
        align-items: flex-end;
        .input-text-box {
          width: 100%;
        }
      }
      .scoring-week-selection {
        display: flex;
        justify-content: space-between;
      }
      label {
        color: $primaryColor;
      }
      .input-label {
        width: 99%;
        height: 26px;
        border: 1px solid $inputEleBorderColor;
        color: $primaryColor;
        border-radius: $primaryBR;
      }
      input[type="date"] {
        border-radius: 3px;
        border: 1px solid $inputEleBorderColor;
        height: 30px;
        width: 100%;
        color: $primaryColor;
        box-sizing: border-box;
        padding-left: $inputLeftSpacing;
        text-transform: uppercase;
        font-family: "Hind Siliguri";
        font-size: $primaryFontSizeXS;
        margin-left: -1px;
      }
    }
  }

  .schedule-input-field-container {
    margin: 10px 20px;
    padding: 0px;
    font-size: $primaryFontSizeS;
    width: 94%;
    label {
      color: $primaryColor;
    }
    .input-label {
      width: 100%;
      height: 26px;
      border: 1px solid $inputEleBorderColor;
      color: $primaryColor;
      border-radius: $primaryBR;
      font-family: $fontFamily;
      font-size: $primaryFontSizeXS;
      font-weight: 400;
      &.disabled {
        color: $disabledFontColor;
      }
    }
  }

  .popup-btn {
    display: flex;
    justify-content: flex-end;
    margin: 25px 15px 0px;
    border-top: none !important;
    padding: 10px 0px;
  }
}
