@import "../../../styles/sass/NewThemeVariables.scss";

.error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40rem;
  flex-direction: column;
  img {
    width: 300px;
    height: 300px;
  }
  p {
    font-size: 14px;
    font-weight: bold;
    color: rgb(70, 89, 106);
    text-align: center;
  }
}
