@import "../../../../styles/sass/NewThemeVariables.scss";

// .myconfig-switch-icons {
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   width: 100%;
//   font-size: 40;

//   .myconfig-icons {
//     cursor: pointer;
//     opacity: 0.2;
//     color: #46596a;
//     margin: 5px;
//     background-color: white;

//     &.active {
//       opacity: 1;
//       color: white;
//       background-color: #46596a;
//     }
//   }
// }

.no-configs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2%;
  img {
    height: 20vh;
    width: 100%;
  }
  .no-data {
    color: $primaryColor;
    text-align: center;
    font-size: 14px;
  }
}
.cmu {
  padding: 5%;
}

.no-data {
  color: $primaryColor;
  text-align: center;
  font-size: 14px;
}

.my-configs-container.disabled-pointer {
  opacity: 0.2;
  pointer-events: none;
}
